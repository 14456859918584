import {
  API_URL,
  actionCreator,
  jsonApiHeader,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
  createRequestActionTypes,
} from './../../../utils/utilActions';

export {
  API_URL,
  actionCreator,
  jsonApiHeader,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const ERROR_MESSAGE_ON_FAILURE = 'Something went wrong! Please contact to admin.';

export const API_GET_USER = `${API_URL}/user/carer`;
export const API_GET_CARER = `${API_URL}/carer`;
export const API_UPDATE_CARER = `${API_URL}/carer/update`;
export const API_GET_VISIT = `${API_URL}/visit`;
export const API_GET_CONTRACT = `${API_URL}/contract/carer/sign`;
export const API_GET_ALL_PAYSLIPS = `${API_URL}/payment/payslips`;
export const API_GET_PAYSLIP_BY_ID = `${API_URL}/payment/payslip`;
export const API_GET_PAYMENT_INFO = `${API_URL}/payment/info`;
export const API_GET_SCHEDULE = `${API_URL}/carer/schedule`;
export const API_UPDATE_SCHEDULE = `${API_URL}/carer/schedule`;
export const API_GET_NOTIFICATIONS = `${API_URL}/notifications/get`;
export const API_UPDATE_NOTIFICATION = `${API_URL}/notifications/update`;
export const API_UPDATE_CONTRACT = `${API_URL}/contract/update`;
export const API_CHAT_AUTHENTICATE = `${API_URL}/chat/authenticate`;
export const API_CHAT_USER_ROOMS = `${API_URL}/chat/user/rooms`;
export const API_CHANGE_PASSWORD = `${API_URL}/user/change-password`;
export const API_GET_CLIENT_LIST = `${API_URL}/client-list`;
export const API_GET_CAREPLAN_ACTIVE = `${API_URL}/careplan/active`;
export const API_DOC_UPLOAD = `${API_URL}/doc-upload`;
export const API_DOC_MANAGE = `${API_URL}/doc-manage`;
export const API_TRACKING = `${API_URL}/tracking`;
export const API_GET_PLACEMENTS = `${API_URL}/clients/placements`;
export const API_GET_PLACEMENT_DETAILS = `${API_URL}/client`;

export const CarerActionTypes = {
  get_User: createRequestActionTypes('GET_USER'),
  get_Carer: createRequestActionTypes('GET_CARER'),
  update_Carer: createRequestActionTypes('UPDATE_CARER'),
  get_visit: createRequestActionTypes('GET_VISIT'),
  update_visit: createRequestActionTypes('UPDATE_VISIT'),
  get_contract: createRequestActionTypes('GET_CONTRACT'),
  update_contract: createRequestActionTypes('UPDATE_CONTRACT'),
  get_all_payslips: createRequestActionTypes('GET_ALL_PAYSLIPS'),
  get_payslip_by_id: createRequestActionTypes('GET_ALL_PAYSLIP_BY_ID'),
  get_Payment_Info: createRequestActionTypes('GET_PAYMENT_INFO'),
  get_schedule: createRequestActionTypes('GET_SCHEDULE'),
  update_schedule: createRequestActionTypes('UPDATE_SCHEDULE'),
  get_notifications: createRequestActionTypes('GET_NOTIFICATIONS'),
  update_notification: createRequestActionTypes('UPDATE_NOTIFICATION'),
  chat_user_rooms: createRequestActionTypes('CHAT_USER_ROOMS'),
  change_Password: createRequestActionTypes('CHANGE_PASSWORD'),
  get_Client_List: createRequestActionTypes('GET_CLIENT_LIST'),
  get_Careplan_Active: createRequestActionTypes('GET_CARE_PLAN_ACTIVE'),
  check_In: createRequestActionTypes('VISIT_CHECK_IN'),
  upload_Doc: createRequestActionTypes('DOC_UPLOAD'),
  list_Docs: createRequestActionTypes('DOC_LIST'),
  download_Doc: createRequestActionTypes('DOC_DOWNLOAD'),
  user_Tracking: createRequestActionTypes('USER_TRACKING'),
  get_Doc_Summary:  createRequestActionTypes('GET_DOC_SUMMARY'),
};

export const MODULE = 'CARER';
