import {
  API_URL,
  actionCreator,
  jsonApiHeader,
  checkHttpStatus,
  showSuccessMessage,
  createRequestActionTypes,
  showErrorMessage
} from './../../../utils/utilActions';

export {
  API_URL,
  actionCreator,
  jsonApiHeader,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage
};

export const ERROR_MESSAGE_ON_FAILURE = 'Something went wrong! Please contact to admin.';

export const API_GET_CLIENT = `${API_URL}/client`;
export const API_GET_VISITS_BY_CLIENT = `${API_URL}/visit`;
export const API_GET_VISITS_BY_ID = `${API_URL}/visit`;
export const API_UPDATE_VISITS_BY_ID = `${API_URL}/visit`;
export const API_CREATE_VISIT = `${API_URL}/visit/create`;
export const API_GET_PAYMENT_INFO = `${API_URL}/payment/info`;
export const API_GET_CARER_LIST = `${API_URL}/carer-list`;
export const API_UPDATE_CLIENT = `${API_URL}/client/update`;
export const API_ASSIGN_CARER = `${API_URL}/visit/assign-carer`;
export const API_GET_CAREPLAN_LIST = `${API_URL}/careplan`;
export const API_GET_CAREPLAN_ACTIVE = `${API_URL}/careplan/active`;
export const API_GET_NOTIFICATIONS = `${API_URL}/notifications/get`;
export const API_UPDATE_NOTIFICATION = `${API_URL}/notifications/update`;
export const API_GET_CARER_BY_ID = `${API_URL}/carer`;
export const API_CARE_TEAM_REQUEST = `${API_URL}/care-request`;
export const API_GET_CONTRACT = `${API_URL}/contract/carer/sign`;
export const API_UPDATE_CONTRACT = `${API_URL}/contract/update`;
export const API_CHAT_AUTHENTICATE = `${API_URL}/chat/authenticate`;
export const API_CHAT_USER_ROOMS = `${API_URL}/chat/user/rooms`;
export const API_GET_CARE_TEAM = `${API_URL}/care-request`;
export const API_GET_CARER = `${API_URL}/carer`;
export const API_CHANGE_PASSWORD = `${API_URL}/user/change-password`;
export const API_GET_DASHBOARD = `${API_URL}/client-dashboard`;
export const API_CALENDER = `${API_URL}/calender`;
export const API_PROPOSED_CARERS = `${API_URL}/proposed-carers`;
export const API_PAYMENT_INFO = `${API_URL}/payment/connect-user`;
export const API_STRIPE_CHECKOUT_SESSION = `${API_URL}/payment/stripe-checkout-session`;

export const ClientActionTypes = {
  get_client: createRequestActionTypes('GET_CLIENT'),
  get_User: createRequestActionTypes('GET_USER'),
  get_visits_by_client: createRequestActionTypes('GET_VISITS_BY_CLIENT'),
  get_visits_by_id: createRequestActionTypes('GET_VISITS_BY_ID'),
  get_Visits_By_Day: createRequestActionTypes('GET_VISITS_BY_DAY'),
  create_Visit: createRequestActionTypes('CREATE_VISIT'),
  update_visits_by_id: createRequestActionTypes('UPDATE_VISITS_BY_ID'),
  get_all_invoices: createRequestActionTypes('GET_ALL_INVOICES'),
  get_invoice_by_id: createRequestActionTypes('GET_INVOICE_BY_ID'),
  get_Payment_Info: createRequestActionTypes('GET_PAYMENT_INFO'),
  get_carer_list: createRequestActionTypes('GET_CARER_LIST'),
  update_client: createRequestActionTypes('UPDATE_CLIENT'),
  assign_carer: createRequestActionTypes('ASSIGN_CARER'),
  get_careplan_list: createRequestActionTypes('GET_CAREPLAN_LIST'),
  get_careplan_active: createRequestActionTypes('GET_CAREPLAN_ACTIVE'),
  get_notifications: createRequestActionTypes('GET_NOTIFICATIONS'),
  update_notification: createRequestActionTypes('UPDATE_NOTIFICATIONS'),
  get_carer_by_id: createRequestActionTypes('GET_CARER_BY_ID'),
  add_to_care_team: createRequestActionTypes('CARE_TEAM_REQUEST'),
  get_contract: createRequestActionTypes('GET_CONTRACT'),
  update_contract: createRequestActionTypes('UPDATE_CONTRACT'),
  chat_user_rooms: createRequestActionTypes('CHAT_USER_ROOMS'),
  update_Invoice: createRequestActionTypes('UPDATE_INVOICE'),
  get_Care_Team: createRequestActionTypes('GET_CARE_TEAM'),
  get_Carer: createRequestActionTypes('GET_CARER'),
  change_Password: createRequestActionTypes('CHANGE_PASSWORD'),
  get_Dashboard: createRequestActionTypes('GET_DASHBOARD'),
  get_Calendler_Visits_1MClient: createRequestActionTypes('GET_CALENDER_VISITS_1M'),
  get_Carers_Prop: createRequestActionTypes('GET_CARERS_PROP'),
  update_Carers_Prop: createRequestActionTypes('UPDATE_CARERS_PROP'),
  submit_Payment_Info: createRequestActionTypes('SUBMIT_PAYMENT_INFO'),
  create_setup_intent: createRequestActionTypes('CREATE_SETUP_INTENT'),
  submit_stripe_checkout_session: createRequestActionTypes('SUBMIT_STRIPE_CHECKOUT_SESSION'),
};

export const MODULE = 'CLIENT';
