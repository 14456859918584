import { applyMiddleware, compose, createStore } from 'redux';
import reducers from './reducers'
import thunk from 'redux-thunk';


const createEdynStore = () => createStore(
  reducers,
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : '',
  compose(
    applyMiddleware(thunk)
  )
);

export default createEdynStore