/**
 * @desc Main reducer - that combines reducers of each module.
 */

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'; // SAYING use redux form reducers as reducers
import AdminReducer from '../modules/admin/reducers';
import CarerReducer from '../modules/carers/reducers';
import ClientReducer from '../modules/clients/reducers';


export const reducersObject = {
  form: formReducer,
  admin: AdminReducer,
  carer: CarerReducer,
  client: ClientReducer,
};

const appReducer = combineReducers(reducersObject);

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
