import { CarerActionTypes } from '../actions/constants';

const initialState = {
  loading: false,
  visit: [],
  user: { name: {}, postTraining: {}} ,
  carer: { name: {}, postTraining: {}, address: {}, interest: {select: []}} ,
  paymentInfo: {},
  actionSuccess: 'init',
  clientList: { data: [] },
  carePlan: { data: {} },
  docList: { data: [] },
  doc: { data: {} },
  payslips: { data: [] },
  payslip: { data: {} }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CarerActionTypes.get_User.REQUEST:
    case CarerActionTypes.get_Carer.REQUEST:
    case CarerActionTypes.get_visit.REQUEST:
    case CarerActionTypes.get_contract.REQUEST:
    case CarerActionTypes.get_all_payslips.REQUEST:
    case CarerActionTypes.get_payslip_by_id.REQUEST:
    case CarerActionTypes.get_Payment_Info.REQUEST:
    case CarerActionTypes.get_schedule.REQUEST:
    case CarerActionTypes.update_schedule.REQUEST:
    case CarerActionTypes.get_notifications.REQUEST:
    case CarerActionTypes.update_notification.REQUEST:
    case CarerActionTypes.update_visit.REQUEST:
    case CarerActionTypes.chat_user_rooms.REQUEST:
    case CarerActionTypes.change_Password.REQUEST:
    case CarerActionTypes.get_Client_List.REQUEST:
    case CarerActionTypes.get_Careplan_Active.REQUEST:
    case CarerActionTypes.upload_Doc.REQUEST:
    case CarerActionTypes.list_Docs.REQUEST:
    case CarerActionTypes.get_Doc_Summary.REQUEST:
    case CarerActionTypes.download_Doc.REQUEST:
    case CarerActionTypes.update_contract.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarerActionTypes.get_User.SUCCESS: 
      return {
        ...state,
        loading: false,
        user: payload.data,
      };
    case CarerActionTypes.get_Carer.SUCCESS: 
      return {
        ...state,
        loading: false,
        carer: payload.data,
      };
    case CarerActionTypes.get_visit.SUCCESS:
      return {
        ...state,
        loading: false,
        visit: payload,
      };
    case CarerActionTypes.get_contract.SUCCESS:
      return {
        ...state,
        loading: false,
        contract: payload,
      };
    case CarerActionTypes.get_all_payslips.SUCCESS:
      return {
        ...state,
        loading: false,
        payslips: payload,
      };
    case CarerActionTypes.get_payslip_by_id.SUCCESS:
      return {
        ...state,
        loading: false,
        payslip: payload,
      };
    case CarerActionTypes.get_Payment_Info.SUCCESS:
      return {
        ...state,
        loading: false,
        paymentInfo: payload,
      };
    case CarerActionTypes.get_schedule.SUCCESS:
      return {
        ...state,
        loading: false,
        schedule: payload,
      };
    case CarerActionTypes.update_schedule.SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleUpdate: payload,
      };
    case CarerActionTypes.get_notifications.SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: payload,
      };
    case CarerActionTypes.update_notification.SUCCESS:
      return {
        ...state,
        loading: false,
        updateNotifications: payload,
      };
    case CarerActionTypes.update_visit.SUCCESS:
      return {
        ...state,
        loading: false,
        updateVisit: payload,
      };
    case CarerActionTypes.chat_user_rooms.SUCCESS:
      return {
        ...state,
        loading: false,
        userRooms: payload,
      };
    case CarerActionTypes.change_Password.SUCCESS:
      return {
        ...state,
        loading: false,
        actionSuccess: true
      };
    case CarerActionTypes.get_Client_List.SUCCESS:
      return {
        ...state,
        loading: false,
        clientList: payload
      };
    case CarerActionTypes.get_Careplan_Active.SUCCESS:
      return {
        ...state,
        loading: false,
        carePlan: payload
      };
    case CarerActionTypes.upload_Doc.SUCCESS:
      return {
        ...state,
        loading: false,
        response: payload
      }
    case CarerActionTypes.list_Docs.SUCCESS: 
      return {
        ...state,
        loading: false,
        docList: payload
      }
    case CarerActionTypes.get_Doc_Summary.SUCCESS: 
      return {
        ...state,
        loading: false,
        docSumm: payload.data
      }
    case CarerActionTypes.download_Doc.SUCCESS:
      return {
        ...state,
        loading: false,
        doc: payload
      }
    case CarerActionTypes.get_User.FAILURE:
    case CarerActionTypes.get_visit.FAILURE:
    case CarerActionTypes.get_contract.FAILURE:
    case CarerActionTypes.get_all_payslips.FAILURE:
    case CarerActionTypes.get_payslip_by_id.FAILURE:
    case CarerActionTypes.get_Payment_Info.FAILURE:
    case CarerActionTypes.get_schedule.FAILURE:
    case CarerActionTypes.update_schedule.FAILURE:
    case CarerActionTypes.get_notifications.FAILURE:
    case CarerActionTypes.update_notification.FAILURE:
    case CarerActionTypes.update_visit.FAILURE:
    case CarerActionTypes.chat_user_rooms.FAILURE:
    case CarerActionTypes.change_Password.FAILURE:
    case CarerActionTypes.get_Client_List.FAILURE:
    case CarerActionTypes.get_Careplan_Active.FAILURE:
    case CarerActionTypes.upload_Doc.FAILURE:
    case CarerActionTypes.list_Docs.FAILURE:
    case CarerActionTypes.download_Doc.FAILURE:
    case CarerActionTypes.get_Doc_Summary.FAILURE:
    return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
