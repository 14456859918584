import { ClientActionTypes } from '../actions/constants';

const initialState = {
  carer: {data: {name: {}}},
  user: {data: {}},
  loading: false,
  clients: [],
  carePlan: {},
  paymentInfo: {},
  careTeam: {data: {team: {}}},
  carersList: [],
  dashboard: {data: {carePlan: {}, previousCareStatement: {duration: {}}, carePlan: {}, next10Visits: [], prev28Wellness: [], wizard: {}, prevVisit: {carerUpdate: {wellness: {}}}}},
  invoices: {data: {invoices: [{duration: {}, carers: []}], invoiceEst: {duration: {}}}},
  clientMonth: [],
  carePlan: { data: {} },
  carersProp: { data: [] },
  visits: { data: [] }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ClientActionTypes.get_client.REQUEST:
    case ClientActionTypes.get_User.REQUEST:
    case ClientActionTypes.get_visits_by_client.REQUEST:
    case ClientActionTypes.get_visits_by_id.REQUEST:
    case ClientActionTypes.get_all_invoices.REQUEST:
    case ClientActionTypes.get_invoice_by_id.REQUEST:
    case ClientActionTypes.get_Payment_Info.REQUEST:
    case ClientActionTypes.update_visits_by_id.REQUEST:
    case ClientActionTypes.get_carer_list.REQUEST:
    case ClientActionTypes.update_client.REQUEST:
    case ClientActionTypes.assign_carer.REQUEST:
    case ClientActionTypes.get_careplan_list.REQUEST:
    case ClientActionTypes.get_careplan_active.REQUEST:
    case ClientActionTypes.get_notifications.REQUEST:
    case ClientActionTypes.update_notification.REQUEST:
    case ClientActionTypes.get_carer_by_id.REQUEST:
    case ClientActionTypes.chat_user_rooms.REQUEST:
    case ClientActionTypes.get_Care_Team.REQUEST:
    case ClientActionTypes.get_Dashboard.REQUEST:
    case ClientActionTypes.get_Calendler_Visits_1MClient.REQUEST:
    case ClientActionTypes.get_Carers_Prop.REQUEST:
    case ClientActionTypes.get_Visits_By_Day.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ClientActionTypes.get_client.SUCCESS:
      return {
        ...state,
        loading: false,
        client: payload,
      };
    case ClientActionTypes.get_User.SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case ClientActionTypes.get_visits_by_client.SUCCESS:
    case ClientActionTypes.get_Visits_By_Day.SUCCESS:
      return {
        ...state,
        loading: false,
        visits: payload,
      };
    case ClientActionTypes.get_visits_by_id.SUCCESS:
      return {
        ...state,
        loading: false,
        visit: payload,
      };
    case ClientActionTypes.get_all_invoices.SUCCESS: 
      return {
        ...state,
        loading: false,
        invoices: payload,
      };
    case ClientActionTypes.get_invoice_by_id.SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: payload,
      };
    case ClientActionTypes.get_Payment_Info.SUCCESS:
      return {
        ...state,
        loading: false,
        paymentInfo: payload,
      };
    case ClientActionTypes.update_visits_by_id.SUCCESS:
      return {
        ...state,
        loading: false,
        updateVisit: payload,
      };
    case ClientActionTypes.get_carer_list.SUCCESS:
      return {
        ...state,
        loading: false,
        carersList: payload,
      };
    case ClientActionTypes.update_client.SUCCESS:
      return {
        ...state,
        loading: false,
        updateClient: payload,
      };
    case ClientActionTypes.assign_carer.SUCCESS:
      return {
        ...state,
        loading: false,
        assignCarer: payload,
      };
    case ClientActionTypes.get_careplan_list.SUCCESS:
      return {
        ...state,
        loading: false,
        careplanList: payload,
      };
    case ClientActionTypes.get_careplan_active.SUCCESS:
      return {
        ...state,
        loading: false,
        carePlan: payload,
      };
    case ClientActionTypes.get_notifications.SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: payload,
      };
    case ClientActionTypes.update_notification.SUCCESS:
      return {
        ...state,
        loading: false,
        notificationUpdate: payload,
      };
    case ClientActionTypes.get_carer_by_id.SUCCESS: 
      return {
        ...state,
        loading: false,
        carer: payload,
      };
    case ClientActionTypes.chat_user_rooms.SUCCESS:
      return {
        ...state,
        loading: false,
        userRooms: payload,
      };
    case ClientActionTypes.get_Care_Team.SUCCESS: 
      return {
        ...state,
        loading: false,
        careTeam: payload,
      };
    case ClientActionTypes.get_Dashboard.SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: payload,
      };
    case ClientActionTypes.get_Calendler_Visits_1MClient.SUCCESS: 
      return {
        ...state,
        loading: false,
        clientMonth: payload,
      };
    case ClientActionTypes.get_Carers_Prop.SUCCESS:
      return {
        ...state,
        loading: false,
        carersProp: payload,
      };
    case ClientActionTypes.get_client.FAILURE:
    case ClientActionTypes.get_User.FAILURE:
    case ClientActionTypes.get_all_invoices.FAILURE:
    case ClientActionTypes.get_invoice_by_id.FAILURE:
    case ClientActionTypes.get_Payment_Info.FAILURE:
    case ClientActionTypes.get_visits_by_client.FAILURE:
    case ClientActionTypes.get_visits_by_id.FAILURE:
    case ClientActionTypes.get_Visits_By_Day.FAILURE:
    case ClientActionTypes.update_visits_by_id.FAILURE:
    case ClientActionTypes.get_carer_list.FAILURE:
    case ClientActionTypes.update_client.FAILURE:
    case ClientActionTypes.assign_carer.FAILURE:
    case ClientActionTypes.get_careplan_list.FAILURE:
    case ClientActionTypes.get_careplan_active.FAILURE:
    case ClientActionTypes.get_notifications.FAILURE:
    case ClientActionTypes.update_client.FAILURE:
    case ClientActionTypes.get_carer_by_id.FAILURE:
    case ClientActionTypes.chat_user_rooms.FAILURE:
    case ClientActionTypes.get_Care_Team.FAILURE:
    case ClientActionTypes.get_Dashboard.FAILURE:
    case ClientActionTypes.get_Calendler_Visits_1MClient.FAILURE:
    case ClientActionTypes.get_Carers_Prop.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
