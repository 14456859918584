import history from '../../history';
import { createAuth0Client } from '@auth0/auth0-spa-js'

import IdTokenVerifier from 'idtoken-verifier';

const auth0Manager = (() => {
  let auth0Client;
  let isAuth0Loading;


  const init = async function() {
    if (!auth0Client && !isAuth0Loading) {
      isAuth0Loading = true
      auth0Client = await createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        useRefreshTokens: true,
        cacheLocation:'localstorage',
        authorizationParams: {
          redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
          connection: 'Username-Password-Authentication',
          audience: process.env.REACT_APP_API_AUDIENCE,
        }
      })
      isAuth0Loading = false
      return
    }
    throw new Error('Auth0 already initialised')
  }

  const getClient = function() {
    if (!auth0Client) {
      throw new Error('Auth0Client not initialised')
    }

    return auth0Client;
  }

  return {
    init,
    getClient,
  }
})()


export default class Auth {
  verifier = new IdTokenVerifier({
    issuer: process.env.REACT_APP_AUTH0_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_CLIENT_ID
  });

  constructor() {
    // this.changePassword = this.changePassword.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.userHasAccess = this.userHasAccess.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.handleSessionRedirect = this.handleSessionRedirect.bind(this);
  }

  async init() {
    try {
      await auth0Manager.init()
    } catch (error) {
      console.error(error)
    }
  }

  async login() {
    try {
      if (window.location.pathname === '/login-redirect') {
        await auth0Manager.getClient().loginWithRedirect()
        return
      }

      await auth0Manager.getClient().loginWithRedirect({
        appState:window.location
      })
    } catch (error) {
      console.error(error)
    }
  }

  async handleAuthentication() {
    
    try {
      const auth0 = auth0Manager.getClient()
      let getAuth0CallbackState = await auth0.handleRedirectCallback()

      const getTokenResult = await auth0.getTokenSilently({detailedResponse:true});
      const userInfo = await auth0.getUser()
      this.updateLocalStorage(getTokenResult, userInfo)
      this.handleSessionRedirect(userInfo, getAuth0CallbackState?.appState)
    } catch (error) {
      console.error(error)
    }
  }

  async checkIfValidSessionExists() {
    const auth0 = auth0Manager.getClient();
    try {
      await this.getAccessTokenAsync()
      return true
    } catch (error) {
      return false
    }
  }

  updateLocalStorage(authResult, user) {
    let expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());

    localStorage.setItem('access_token', authResult.access_token);
    localStorage.setItem('id_token', authResult.id_token);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('email', user.email);

    // Important - the claims defined at http://localhost:3000/user_metadata are defined in a 'post-login flow' & action in auth0
    const customClaims = user['http://localhost:3000/user_metadata']
    localStorage.setItem('userType', customClaims.type);
    localStorage.setItem('userId', customClaims.id);
    localStorage.setItem('edyn-featureToggles', JSON.stringify(customClaims.featureToggles || []))
  }

  handleSessionRedirect(user, appState) {
      const loginUserType = localStorage.getItem('userType');

      if (loginUserType === 'carer') {
        if (appState && appState.href !== `${process.env.REACT_APP_MARKETING_SITE_URL}/`) {
          history.replace(appState.pathname)
          return
        }
        history.replace('/portal-carer/overview');

        return;
      }
      if (loginUserType === 'client') {
        if (appState && appState.href !== `${process.env.REACT_APP_MARKETING_SITE_URL}/`) {
          history.replace(appState.pathname)
          return
        }
        history.replace('/portal-client/dashboard');

        return ;
      }
      if (loginUserType === 'admin') {
        if (appState && appState.href !== `${process.env.REACT_APP_MARKETING_SITE_URL}/`) {
          history.replace(appState.pathname)
          return
        }
        history.replace('/portal-admin/visits');
        return ;
      }

      console.error(`Unkown user type on token ${JSON.stringify(user)}, extracted user type: ${loginUserType}`)

      history.replace('/');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.clear();

    if (window.Intercom) {
      Intercom('shutdown')
    }

    const auth0 = auth0Manager.getClient()

    auth0.logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams:{
        returnTo: process.env.REACT_APP_MARKETING_SITE_URL,
      }
    });
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  getUserId() {
    // Get user id throughout the application
    if (this.isAuthenticated()) {
      return localStorage.getItem('userId');
    }
  }

  getUserName() {
    // Get user id throughout the application
    if (this.isAuthenticated()) {
      return localStorage.getItem('name');
    }
  }
  
  getEmail() {
    // Get user id throughout the application
    if (this.isAuthenticated()) {
      return localStorage.getItem('email');
    }
  }
  
  isAuthenticated() {
    // Check whether the current time is past the access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  isFeatureEnabled(featureFlag) {
    if (this.isAuthenticated()) {
      try {
        return JSON.parse(localStorage.getItem('edyn-featureToggles') || '[]').includes(featureFlag)

      } catch (e) {
        console.error(e)

        return false;
      }
    }
  }

  userHasAccess(userType) {
    // check the path user tried to access is authorized or not
    const userAccess = localStorage.getItem('userType');
    if (userAccess) {
      return userType.every((type) => userAccess.includes(type));
    }
    return false;
  }

  getAccessTokenAsync() {
    const auth0 = auth0Manager.getClient()
    return auth0.getTokenSilently({detailedResponse: true}).then(async (res) => {
      if (res.access_token !== this.getAccessToken()) {
        const user = await auth0Manager.getClient().getUser()
        this.updateLocalStorage(res, user)  
      }
      return res.access_token
    })
  }
}
