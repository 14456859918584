import React, { Component, useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import history from '../history';

import Auth from '../components/auth/auth';

// import AdminPortal from '../modules/admin';
// import CarerPortal from '../modules/carers';
// import ClientPortal from '../modules/clients';
// import PublicPortal from '../modules/public';

import asyncComponent from '../components/AsyncComponent';

const AsyncAdminPortal = asyncComponent(() => import("../modules/admin"));
const AsyncCarerPortal = asyncComponent(() => import("../modules/carers"));
const AsyncClientPortal = asyncComponent(() => import("../modules/clients"));
const AsyncPublicPortal = asyncComponent(() => import("../modules/public"));
const AsyncFindMyCarerPortal = asyncComponent(() => import("../modules/findMyCarer"));


(() => {
  let prevLocation = history.location

  history.listen((location) => {
    if (!window._paq) return

    const { _paq } = window
    const url = location && location.pathname + location.search + location.hash
    const prevUrl =
      prevLocation &&
      prevLocation.pathname + prevLocation.search + prevLocation.hash
      
    prevLocation = location

    // document.title workaround stolen from:
    // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
    const sendPageView = () => {
      const { title } = document

      prevUrl && _paq.push(['setReferrerUrl', prevUrl])
      _paq.push(['setCustomUrl', url])
      _paq.push(['setDocumentTitle', title])
      _paq.push(['trackPageView'])
      _paq.push(['enableLinkTracking'])
      _paq.push(['trackAllContentImpressions'])
    }

    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(32, 0)
    setTimeout(sendPageView, delay)
  })
})()


const LoginRedirect = ({auth, isAuthenticated}) => {
    useEffect(() => {
      if (isAuthenticated) {
        auth.handleSessionRedirect()
      } else {
        auth.login()
      }
    }, [])

    return (
      <div>
          Please wait to be redirected to our login page
      </div>
    )
}

export default class RouteComponent extends Component {
  constructor() {
    super()
    this.state = {
      isAuth0Loading:true,
      isAuthenticated:false
    }
  }

  componentDidMount() {
    const auth = new Auth();
    auth.init()
    .then(() => {
      if (history.location.pathname === '/callback') {
        return auth.handleAuthentication()
      }
    })
    .then(() => auth.checkIfValidSessionExists())
    .then(isAuthenticated => {
      this.setState({isAuth0Loading:false, isAuthenticated})
    })
  }

  render() {
    const auth = new Auth()
    if (this.state.isAuth0Loading) {
      return (        
        <div className="spinner-edyn"></div>
      )
    }

    try {
      if (auth.isAuthenticated()) {
        Sentry.setUser({
          id: `${localStorage.getItem('userType')}:${auth.getUserId()}`
        });
      }
    } catch (e) {}

    let pathnameArr = (history.location.pathname).split('/');
    let portalPath = pathnameArr ? pathnameArr[1] : '';

    return (
      <Router history={history}>
        <Switch>
          <Route path='/login-redirect' render={() => {
                return (
                    <LoginRedirect auth={auth} isAuthenticated={this.state.isAuthenticated}/>
                )
            }
          } />
          <Route path='/portal-admin' render={(props) => {
            if (!this.state.isAuthenticated) {
              auth.login()
            }
            if (!auth.userHasAccess(['admin'])) {
              window.location.assign(process.env.REACT_APP_MARKETING_SITE_URL)

              return <span>404</span>
            }
              return <AsyncAdminPortal auth={auth} {...props} />
          }}/>
          <Route path='/portal-carer' render={(props) => {
            if (!this.state.isAuthenticated) {
              auth.login()
            }
            if(!auth.userHasAccess(['carer'])) {
              window.location.assign(process.env.REACT_APP_MARKETING_SITE_URL)

              return <span>404</span>
            }

            return <AsyncCarerPortal auth={auth} {...props} />
          }} /> 
          <Route path='/portal-client' render={(props) => {
            if (!this.state.isAuthenticated) {
              auth.login()
            }
            if(!auth.userHasAccess(['client'])) {
              window.location.assign(process.env.REACT_APP_MARKETING_SITE_URL)

              return <span>404</span>
            }

            return <AsyncClientPortal auth={auth} {...props} />
          }} />
          <Route path='/find-my-carer' render={(props) => {
            return <AsyncFindMyCarerPortal {...props} />
          }} />
          <Route path='/' component={AsyncPublicPortal} />       
        </Switch>
      </Router>
    );
  }
}
