import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-table/react-table.css';
import { renderToString } from 'react-dom/server';
// import registerServiceWorker from './registerServiceWorker';


import './scss/custom/basic.css';
import './scss/custom/designer.css';
import './scss/custom/profile-card.css';
import './scss/custom/adminTemplate.css';
import './scss/custom/Public.css';
import './scss/custom/footer.css';
import './scss/custom/availability.css';
import './scss/custom/onBoarding.css';
import './scss/custom/clientPortal.css';
import './scss/custom/carerPortal.css';
import './scss/custom/fab.css';

import { applyMiddleware, compose, createStore } from 'redux';
import allReducers from './reducers';
import App from './app';
import { Provider } from 'react-redux';
import React from 'react';
// import ReactDOM from 'react-dom';
// import { render } from 'react-snapshot';
import { hydrate, render } from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import createEdynStore from './createEdynStore';

Sentry.init({
  dsn: 'https://7b04991abaa240bd89f57e690066b524@o501458.ingest.sentry.io/5582590',
  autoSessionTracking: true,
  environment: process.env.REACT_APP_EDYN_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing(),
  ],


  // We will want to turn this down at some point
  tracesSampleRate: 0.1,
});

const edynStore = createEdynStore();


const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={edynStore}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={edynStore}>
      <App />
    </Provider>,
    rootElement
  );
}
// registerServiceWorker();
