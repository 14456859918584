import React, { Component } from 'react';
import Router from './routers';
import * as Sentry from "@sentry/react";

const Fallback = () => (
  <div className='error-container'>
      <h2 className='service_ww_mx_p_2 error-text'>There has been an error</h2>
      <p className='service_ww_mx_p_2 m-4'>Please click the button below to log out and try again. If the problem persists, please contact the office.</p>
      <a href="/logout" class="error-logout-button">Log Out</a>
  </div>
)


export default class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary fallback={Fallback}>
          <Router />
      </Sentry.ErrorBoundary>
    );
  }
}
